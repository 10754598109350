/* ==========================================================================================
==========================================================================================
========================================================================================== */

// For All Devices ^^^^^^^^^^^^^^
.overflow-x{
  overflow-x: auto;
}

.full-width{
  min-width: 560px;

}

.back-button{
  background: #e03f00;
  padding: 10px 14px;
  vertical-align: middle;
  border: 1px solid #ffac8b;
  border-radius: 50%;
}

.back-button-size{
  font-size: 17px;
}

.chip-input-custom-height {
  height: 24px !important;
  padding-bottom: 9px !important;
  width: 115px !important;
  font-size: 10.5px !important;
}

.coin-input-custom-height {
  height: 28px !important;
  max-width: 40px !important;
  min-width: 40px !important;
  font-size: 13px !important;
}

.input-group-xs span, .input-group-xs input{
  height: 24px !important;
  font-size: 10.5px !important;
}

.input-group-xs input{
  min-width: 115px !important;
  max-width: 130px !important;
  padding-bottom: 9px !important;
}



.input-group-xs{
  flex-wrap: nowrap !important;
}

.btn-orange,
.valuebet-bg-color {
  background-color: #e03f00 !important;
}

.vb-text-orange {
  color: #e03f00 !important;
}

.btn-orange:hover {
  background-color: #a53204 !important;
}

.bg-vb-dark {
  background: #0b0b0b !important;
}

.bg-vb-dark-light {
  background: #202020!important;
}

.font-weight-normal {
  font-weight: normal !important; /* Use !important to override Bootstrap styles */
}

.enable-li-stype li{
  list-style: circle;
}

.vb-banner{
  margin-top: 12px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1380px;
}

.live-dot{
  position: absolute;
  top: -11px;
  font-size: 20px;
  right: 0px;
}

.beta-icon{
  position: absolute;
  top: -16px;
  font-size: 10px;
  left: 53px;
}

.beta-icon-110-left{
  left: 110px !important;
}

.pre-space-wrap{
  white-space: pre-wrap;
  font-family: inherit;
  font-size: 14px;
}

.coin-modal-margin {
  margin: 0 1rem;
}

.desk-tokentransferbutton{
  display: unset;
}

.mob-tokentransferbutton{
  display: none;
}

.btn-shadow {
  box-shadow: 1px 2px #ff8975 !important;
}

.letter-space{
  letter-spacing: 0.7px;
}

.club-title{
  color: #dbdbdb;
  margin-left: 6px;
  font-size: 12px;
  border-radius: 3px;
  border: 0.2px solid gray;
  padding: 1px 2px;
  letter-spacing: 0.3px;
}

.modal-content {
  background: #0b0b0b !important;
  color: white;
  border: 1px solid #e03f00;
}

.custom-active {
  background: #0b0b0b !important;
  color: white !important;
  border-color: #5a5a5a #5a5a5a #0b0b0b #5a5a5a !important;
}

.custom-deactive {
  background: #242424 !important;
  color: white !important;
  border-color: #5a5a5a !important;
}

.seperater {
  background: #2c2c2c;
}

.btn-outline-custom-orange {
  border-color: #e03f00;
  color: #ff6022
}

.btn-outline-custom-orange:hover {
  color: #ffc2aa;
}

.sec-header {
  position: fixed;
  right: 0;
  left: 0;
  top: 59px;
  z-index: 2;
  border: 0;
  background-color: #161616;
  padding-top: 11px;
  padding-bottom: 11px;
  align-items: center;
}

.sec-header-title {
  color: white;
  font-weight: 400 !important;
  margin: 0;
}

.hello-title {
  margin-left:5px !important;
  display: none;
}

.sec-header-first-items {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.sec-header-sec-items {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.d-unset {
  display: unset !important;
}

.locked-features {
  color: #d30505;
  font-size: 18px !important;
}

.section-disable {
  pointer-events: none;
  opacity: 0.5;
}

.table-custom-dark{
    --bs-table-bg: none !important;
    --bs-table-border-color: none !important;
    --bs-table-color: #fff;
}

.wrapper-page{
  margin: 100px auto;
  max-width: 492px;
  position: relative;
}

/* Slider CSS start */
.custom-slider .carousel-control-next,
.custom-slider .carousel-control-prev {
  width: 5%;
  margin: 5px;
  opacity: 0.2;
}

.custom-slider .carousel-indicators li.active {
  background-color: #f4511e;
}

.custom-tag {
  max-width: 100%;
  height: 130px;
  background: black;
}

.thnk-loader{
  width: 4rem;
  height: 4rem;
  margin: 120px 0;
}

/* Slider CSS end */

.bg-black{
  background-color: black !important;
}

/* // Agent section */
/* // --------------------------------------------------------------------------------------------------------- */
.agent-view-container {
  margin-top: 120px !important;
}

.list-view-card {
  border-radius: 5px !important;
  background: #0b0b0b !important;
  border: 1px solid #e03f00;
  min-width: 290px !important;
  max-width: 490px;
  width: -webkit-fill-available;
}

.agent-bottom-button {
  border-radius: 5px;
  background-color: #e92200;
  box-shadow: 1px 1.5px #ff7d7d;
  color: white;
  border: none;
  width: 130px;
  padding: 3px;
  font-weight: 500;
  width: 110px;
  height: 30px;
  font-size: 11px;
}

.agent-bottom-button-disabled {
  border-radius: 5px;
  background-color: #a14c3e;
  box-shadow: 1px 1.5px #ff7d7d;
  color: #ddcaca;
  border: none;
  width: 130px;
  padding: 3px;
  font-weight: 500;
  width: 110px;
  height: 30px;
  font-size: 11px;
}

.agent-select-report-input {
  width: 260px;
  color: white;
  margin: 0px;
  padding-top: 5px;
}

.agent-list-view {
  margin: 13px !important;
  padding-bottom: 20px;
}

.agent-card-container {
  padding-left: 11px !important;
  margin-bottom: 33px;
}

.list-agent-title {
  color: #babbbc;
  display: flex;
  justify-content: center;
}

.agent-identity {
  display: flex !important;
  justify-content: space-between;
}

.platform-id-color {
  color: white !important
}

.agent-list-copy-button {
  border: 0px #0b0b0b;
  background: #0b0b0b;
  color: white;
}

.agent-list-copied-text {
  font-size: 13px;
  font-family: monospace;
  color: green
}

.agent-overview-button {
  margin-top: 4px;
  border-radius: 0px !important;
  background-color: yellow !important;
  color: black !important;
  height: 20px;
  width: 60px;
  border: none;
}

.sort-player {
  color: #ff6c22 !important;
  cursor: pointer;
}

.agent-move-button-container {
  color: blue;
  margin-top: 4px;
  margin-right: 5px;
  text-align: center;
  margin-bottom: 3px;
  font-size: 9px;
}

.agent-link-info {
  text-decoration: underline;
  text-align: center;
  font-size: 14px;
}

.agent-card-cap {
  height: 75px;
  margin-bottom: -21px;
  background: #161313;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 1px solid #e92200;
}

.agent-card-move-button-container {
  color: blue;
  margin-top: 40px;
  margin-right: 5px;
  text-align: end;
  margin-bottom: 20px;
  font-size: 9px;
}

.agent-card-chip-button-container {
  color: blue;
  margin-top: -40px;
  margin-left: 5px;
  text-align: start;
  margin-bottom: 20px;
  font-size: 9px;
}

.agent-avatar {
  width: 80px;
  border: 1px solid #e92200;
  border-radius: 50px;
  background: black;
  padding: 3px;
  position: absolute;
}

.agent-card-identity {
  display: flex;
  text-align: center;
  justify-content: center;
  margin-top: -5px;
  margin-bottom: 5px;
  color: wheat;
}

.agent-info {
  margin-top: 2px;
  text-align: center;
  color: white;
}

.update-plateformid-button {
  box-shadow: none;
  border: none;
}

.agent-popup-button {
  font-size: 10px !important;
  font-weight: bold !important;
}

.agent-tree-container {
  background: #262626;
  margin-left: 10px;
  margin-bottom: 15px;
  margin-top: -10px;
  margin-right: 10px;
}

.agent-tree-container-list {
  background: #262626;
  margin-bottom: 15px;
  margin-top: -10px;
  margin-right: 10px;
}

.tree-button {
  border: none;
  background: none;
  color: wheat;
}

.tree-arrow {
  font-size: 13px;
  color: darkgray;
}

.agent-card-overview-label {
  margin-top: -12px !important;
  text-align: center;
  border: 5px solid #1e1e1e;
  background-color: #1e1e1e;
  color: darkgrey;
  font-size: 11px;
}

.remove-margin {
  margin: 0px !important;
}

.horizantal-line {
  height: 1px;
  background: #e9220036;
  margin-top: 3px;
  margin-bottom: 3px;
}

.root-loader-fixed, .root-loader-absolute{
  top: 0;
  color: white;
  z-index: 10000;
  height: 100%;
  width: 100%;
  background: #000000a3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root-loader-fixed{
  position: fixed !important;
}

.root-loader-absolute{
  position: absolute !important;
}

/* // --------------------------------------------------------------------------------------------------------- */


/* // Dashboard section
  // --------------------------------------------------------------------------------------------------------- */

.create-club-button {
  border-radius: 15px;
  background-color: #0b0b0b;
  color: white;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  border: 1px solid #4a4a4a;
  height: 30px;
  text-decoration: none !important;
}

.coin-wallet-valubet {
  border-radius: 15px;
  background-color: #ffffff;
  color: black;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  text-decoration: none !important;
}

.create-club-button:hover {
  background-color: #24211e;
  color: white;
}

.join-club-button {
  border-radius: 15px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 30px;
  background-color: #e03f00;
  color: white;
  text-decoration: none !important;
}

.join-club-button:hover {
  background-color: #cb2b10;
  color: white;
}

.club-icon {
  border: 2px solid #d52b00;
  width: 100px;
  padding: 4px;
  background: #000000;
  box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
  border-radius: 50px;
}

.club-owner-section {
  justify-content: center;
  display: flex;
}

.club-owner-inner {
  font-size: 16px;
  font-weight: 500;
  font-style: inherit;
  color: white;
  margin-left: 4%;
  word-break: break-all;
}

.club-card-container, .club-card-container-pub {
  border-radius: 10px !important;
  background-image: linear-gradient(#0b0b0b, #000000);
  border: 0.1px solid #d52b00;
  min-width: 289px;
  width: 98%;
}

.marquee-wrapper {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  cursor: grab;
}

.marquee-content {
  display: inline-block;
  padding-left: 100%;
  animation: marqueeScroll 10s linear infinite;
  color: white;
  font-size: 16px;
}

.marquee-content.paused {
  animation-play-state: paused;
}

@keyframes marqueeScroll {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}



.club-card-container {
  max-width: 400px;
}

.club-card-container-pub {
  max-width: 500px;
}

.pending-requests {
  width: 26px;
  height: 26px;
  color: red;
  border: 1px solid #FFF;
  border-radius: 50%;
  padding: 6px;
  background-color: #ffd025;
  font-size: smaller;
}

.margin-left-12 {
  margin-left: 12px !important;
}

.club-menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.nav-area {
  display: block !important;
  padding: 0.5rem 0.2rem !important;
}

.copied-id {
  color: green;
  font-size: 13px;
  font-family: monospace;
}

.rename-form-padding {
  padding: 11px;
  text-align: left;
}

.rename-button {
  font-size: 13px !important;
  font-weight: bold !important;
}

.owner-id-copy-button {
  border: 0px #090909;
  background: #090909;
  margin-left: 5px;
  font-size: 13px;
  color: white;
}

.club-cap, .club-cap-regular {
  height: 45px;
  background: #161313;
  font-weight: 500;
  border-radius: 11px 11px 0 0;
  border-bottom: 1px solid #f73300;
}

.club-cap-regular{
  height: 40px;
}

.club-card-title {
  padding-left: 31%;
}

.club-card-title-sm {
  padding-left: 34% !important;
}

.club-logo-setup {
  margin-top: -15px;
}

.club-owner-label-container {
  color: white;
  padding: 5px 10px;
  background-color: #005ce6;
  border: 1px solid #e92200;
  border-radius: 5px;
}

.club-manager-label-container {
  color: black;
  padding: 5px 10px;
  background-color: orange;
  border: 1px solid #e92200;
  border-radius: 5px;
}

.club-agent-label-container {
  color: black;
  padding: 5px 10px;
  background-color: #ffff00;
  border: 1px solid #e92200;
  border-radius: 5px;
}

.club-sub-agent-label-container {
  color: white;
  padding: 5px 10px;
  background-color: purple;
  border: 1px solid #e92200;
  border-radius: 5px;
}

.club-member-label-container {
  color: white;
  padding: 5px 10px;
  background-color: green;
  border: 1px solid #e92200;
  border-radius: 5px;
}

.club-chip-button {
  color: white;
  border: 1px solid #e92200;
  box-shadow: 1px 2px #651608;
  font-size: 10.4px;
  font-weight: 500;
  background: #202020;
  z-index: 1;
  padding: 2px 8px;
  border-radius: 5px;
  letter-spacing: .5px;
}

.bulk-select-font {
  color: #ffffff;
  font-size: 12px;
  font-weight: 100;
}

.select-input-custom-height {
  height: 10px;
}

.transfer-action-button {
  font-size: 12px !important;
  background: none !important;
  padding: 2px 7px !important;
  border-radius: 5px !important;
}

.playnowbtn {
  border-radius: 5px !important;
  background-color: #e92200 !important;
  border: none;
  box-shadow: 1px 2px #ff8975;
  color: white !important;
  width: 100% !important;
  padding: 5px !important;
  font-size: 14px !important;
}

.club-actions {
  background: #282828;
  min-width: 0px !important;
}

.club-actions .dropdown-item {
  padding: 0.25rem 1rem;
  background: linear-gradient(#161616, black);
}

.club-actions .fa {
  color: #ff7a58;
  margin-right: 6px;
}

.club-actions .dropdown-item:hover {
  background: #404040;
}

.chip-info-responsive-view{
  width: 45%;
}

.resetchip-responsive-view{
  display: grid !important;
  width: 53%;
}

.resetchip-responsive-scrollable-view{
  display: none !important;
}

.leaderboard-table-body{
  max-height: 150px;
}

/* // --------------------------------------------------------------------------------------------------------- */

/* // Club setting section 
  // --------------------------------------------------------------------------------------------------------- */

.ui-w-80 {
  width: 80px !important;
  height: auto !important;
}

.btn-default {
  border-color: rgba(24, 28, 33, 0.1) !important;
  background: rgba(0, 0, 0, 0) !important;
  color: #4E5155 !important;
}

.btn {
  cursor: pointer !important;
}

label.btn {
  margin-bottom: 0;
}

.btn-outline-primary {
  border-color: #26B4FF !important;
  background: transparent !important;
  color: #26B4FF !important;
}

.text-light {
  color: #babbbc !important;
}

.card {
  background-clip: padding-box;
  box-shadow: 0 1px 4px rgba(24, 28, 33, 0.012);
  margin-bottom: 30px;
}

.row-bordered {
  overflow: hidden;
}

.account-settings-fileinput {
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.account-settings-links .list-group-item.active {
  font-weight: bold !important;
}

html:not(.dark-style) .account-settings-links .list-group-item.active {
  background: transparent !important;
}

.account-settings-multiselect~.select2-container {
  width: 100% !important;
}

.light-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(24, 28, 33, 0.03) !important;
}

.light-style .account-settings-links .list-group-item.active {
  color: #4e5155 !important;
}

.material-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(24, 28, 33, 0.03) !important;
}

.material-style .account-settings-links .list-group-item.active {
  color: #4e5155 !important;
}

.dark-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(255, 255, 255, 0.03) !important;
}

.dark-style .account-settings-links .list-group-item.active {
  color: #fff !important;
}

.light-style .account-settings-links .list-group-item.active {
  color: #4E5155 !important;
}

.light-style .account-settings-links .list-group-item {
  padding: 0.85rem 1.5rem;
  border-color: rgba(24, 28, 33, 0.03) !important;
}

.club-level-info {
  padding: 3px 8px;
  font-size: 14px;
  width: 61px;
  height: 63px;
  margin-right: 10px;
  background: #e50029;
  border-radius: 5%;
}

.delay-box{
  max-width: 251px;
}

.leaderboard-switch{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

/* // --------------------------------------------------------------------------------------------------------- */

/* 
  // Members section
  // --------------------------------------------------------------------------------------------------------- */

.sett-limit-btn {
  margin-top: 30px;
  letter-spacing: 0.7px;
}

.tbl-avtar {
  width: 40px;
  height: 40px;
  padding: 3px;
  border: 1px solid #e92200;
  background: black;
  border-radius: 58px;
  margin-right: 10px;
}

.mem-action-button {
  color: #e03f00;
  box-shadow: 1px 1.5px #ffa784;
  font-weight: 500;
  font-size: 10.2px;
  background: white;
  border: 1px solid;
  border-radius: 5px;
  min-width: 105px;
}

.player-kickbacks-btn {
  padding: 2px 12px;
  color: black;
  background: yellow;
}


/* // --------------------------------------------------------------------------------------------------------- */

/* // Player Acton section 
  // --------------------------------------------------------------------------------------------------------- */

  .member-dropdown{
    padding: 0;
    color: #fff;
  }

  .member-dropdown .btn-sm:disabled{
    border: none !important;
  }

  .member-label {
    display: flex;
    justify-content: flex-start;
    font-size: 17px;
    margin: 0px;
  }

  .member-actions{
    padding: 15px;
    background-color: #000000;
  }

  .member-actions .dropdown-item {
    padding: 4px 20px;
    margin-top: 5px;
    text-align: center;
    width: 100px;
   
  }

  .member-actions .dropdown-item:active,
  .member-actions .dropdown-item:hover{
    background-color: none !important;
    color: none !important;
  }


  .otyp-text,
  .mtyp-text, 
  .atyp-text,
  .ptyp-text{
    font-weight: 500 !important;
    font-size: 13px;
  }

  .otyp-text{
    background-color: #005ce6;
    color: #fff;
  }

  .mtyp-text {
    background-color: orange;
    color: #000;
  }

  .atyp-text {
    background-color: #ffff00;
    color: #000;
  }

  .ptyp-text {
    background-color: green;
    color: #fff;
  }
  
  /* // --------------------------------------------------------------------------------------------------------- */
  
  /* // Club setting section 
    // --------------------------------------------------------------------------------------------------------- */
  

/* // --------------------------------------------------------------------------------------------------------- */

/* // Outer Header
  // --------------------------------------------------------------------------------------------------------- */


.logsign {
  border-radius: 5px;
  background-color: #e03f00;
  color: white;
  width: 190px;
  letter-spacing: 0.7px;
  font-weight: bold;
  font-size: 12px;
  padding: 9px 10px;
  margin: 2px;
}

/* // --------------------------------------------------------------------------------------------------------- */


/* // Login
  // --------------------------------------------------------------------------------------------------------- */

.signbtn {
  border-radius: 15px;
  background-color: #e03f00;
  color: white;
  letter-spacing: 0.7px;
  width: 250px;
  margin-top: 20px;
  padding: 6px;
  font-weight: bold;
  border: none;
  ;
}

.formcntr {
  width: 95%;
  margin: 0 auto;
}

.clryllo {
  color: #ff5336;
}

.inputerrors {
  color: red;
  margin: 5px 0;
}

.submticon {
  float: right;
  padding-top: 2px;
  padding-right: 5px;
}

/* // --------------------------------------------------------------------------------------------------------- */



/* // Applicants Page
  // --------------------------------------------------------------------------------------------------------- */

.applicants-card-inner {
  min-width: 260px;
  max-width: 315px;
  width: -webkit-fill-available;
  border-radius: 5px !important;
  background: #0b0b0b !important;
  border: 1px solid #e92200;
}

.applicant-avtar {
  width: 93px;
  border: 1px solid #e92200;
  padding: 3px;
  border-radius: 58px;
}

.applicant-uuid {
  color: white;
  font-size: 12px;
}


/* // --------------------------------------------------------------------------------------------------------- */



/* // Create & Join Club Page
  // --------------------------------------------------------------------------------------------------------- */

.cardwapper {
  padding-top: 190px;
}

.cardcontainer {
  border-radius: 15px;
  background-color: #181818;
  min-height: 300px;
}

.submticonclub {
  width: 28px;
  padding-right: -25px;
  float: right;
  padding-top: 4px;
}

.formcntrclub {
  width: 75%;
  margin: 0 auto;
  margin-top: 25px;
}

.crtmclubcard {
  background-color: transparent !important;
  box-shadow: none !important;
  border: none !important;
}

.overridemargintop {
  margin-top: -135px;
}

.clubbanner {
  background-image: linear-gradient(#232323, #000000);
  padding: 12px;
}

.clubbanner-text {
  color: azure;
  font-size: 18px;
}

/* // --------------------------------------------------------------------------------------------------------- */


/* // Profile Setting
  // --------------------------------------------------------------------------------------------------------- */

.profile-setting-title {
  color: aliceblue;
}

.profile-card-container {
  border-radius: 15px;
  background-color: #0b0b0b;
  min-height: 10px;
  margin: 100px auto;
  max-width: 792px;
  position: relative;
  margin-bottom: 100px;
}


.clz-btn {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  margin-top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;

}

.popup-container {
  font-size: 12px;
  color: black;
  box-sizing: border-box;
}

.profile-divider {
  margin-top: 0px;
  border-left: 1px solid #cccccc45;
  height: 500px;
  position: absolute;
  width: 0;
  left: 53%
}

.popup-btn-prfl {
  text-align: right;
  color: black;
  margin-bottom: -30px;
}

// ---------------------------------------------------------------------------------------------------------

/* // Upload Section
// --------------------------------------------------------------------------------------------------------- */

.upld-container {
  border-radius: 15px;
  background-color: #0b0b0b;
  padding-top: 30px;
  min-height: 400px;
  // margin-top: 140px;

}

.grid-show {
  display: grid;
  justify-content: center;
}

// ---------------------------------------------------------------------------------------------------------


// Report
// ----------------------------------------------------------------------------------------------------------

.player-report-container {
  border: 1px solid #e03f00;
  margin-bottom: 15px;
  min-height: 90px;
  border-radius: 15px;
}

.border-bottom-report {
  border-bottom: 1px solid #e03f00;
}

.player-report-container .nav-tabs .nav-link.active {
  color: white !important;
  background-color: #e03f00 !important;
  border-color: #e03f00 !important;
}

.agent-tree-fonts {
  font-size: x-small;
}

.width-85-per {
  width: 85%;
}

.list-nav-layout{
  position: absolute;
  margin-top: 19px;
  margin-left: 85px;
}
// ----------------------------------------------------------------------------------------------------------


// ----------------------------------------------------------------------------------------------------------
// ------ Club Messanger ------

.direct-last-msg{
  text-wrap: nowrap;
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-room{
  display: flex;
  flex-direction: row;
}

.chat-container { 
  border-radius: 8px;  
  box-shadow: 0 0 10px rgba(121, 81, 81, 0.1); 
} 

.blank-chat-container{
  height: 70vh;
}

.message-container { 
  display: flex; 
  flex-direction: column; 
  // max-height:calc(100vh - 120px);
  overflow-y:auto;
} 

.message { 
  max-width: 75%;  
  display: flex; 
  align-items:flex-start; 
} 

.sender-message { 
  color: #fff; 
  align-items: flex-start;
  word-wrap: break-word;  
}

.message-action{
  cursor: pointer;
  margin-top: 10px;
  margin-left: 4px;
}

.reply-message-container{
  padding: 5px 16px;
  width: 100%;
  max-height: 62px;
  // text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
    
}

.reacted-container{
  display: flex;
  justify-content: space-between;
  gap:10px;
}

.left-reaction{
  background-color: rgb(44, 40, 40);
}

.right-reaction{
  background-color: rgba(224, 63, 0, 0.176);
}

.reacted-item{
  display: flex;
  gap:5px;
  max-width:200px;
  height: 25px;
  border-radius: 20px;
  align-items: center;
}

// .reply-message-text {
//   white-space: nowrap;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   max-width: 700px;
// }

.reply-message-items{
  border-left: 4px solid rgba(224, 63, 0, 0.3215686275);
  background-color:#272727;

}

.reply-sender-container{
  border-left: 4px solid #E9D700;
  background-color: rgba(224, 63, 0, 0.176);
  padding: 5px;
  border-radius: 10px 0px 10px 10px;
  
}

.reply-recieve-container{
  border-left:4px solid #68BB59;
  background-color: rgb(44, 40, 40);
  padding: 5px;
  border-radius: 10px 0px 10px 10px;

}

.reply-sender-name{
  color:#E9D700;

}
.reply-sender-name-footer{
  color:#E9D700;
}

.reply-reciever-name{
  color:#68BB59;
}

.message-action-hoverable {
  cursor: pointer;
}

.message-options{
  margin-left: 30px;
  padding: 10px 20px;
  margin-top: 15px;
  width: 200px;
  background-color:#272727;
}

.emoji-panel{
  width: 270px;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px solid white;
  background-color: white;
  border-radius: 20px;
  padding: 0 5px;
}

.bubble-header{
  margin-top: -7px !important;
  margin-bottom: 3px;
  text-align: right;
}

.sender-message-bubble{
  background-color: #1f1f1f; 
  border-radius: 0px 10px 10px 10px; 
  align-self: flex-end; 
}

.receiver-message { 
  color: #fff; 
  align-self: flex-end; 
  align-items: flex-start;
  word-wrap: break-word;
} 

.receiver-message-bubble{
  background-color: #e03f0052; 
  border-radius: 10px 0px 10px 10px; 
  align-self: flex-end; 
}

.msg-date-section{
  font-size: 12px;
  text-align: center;
  font-weight: bold;
  width: 120px;
  background-color:#161414;
  box-shadow: 2px 2px 2px #645c5c;
  border-radius: 10px;
}

.member-list-container{
  max-height:calc(100vh - 450px);
  overflow-y: auto;
  scrollbar-width: thin; 
  scrollbar-color: #ccc transparent;
}

.date-fixed {
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  color:white;
}

.avatar { 
  width: 30px; 
  height: 30px; 
  border-radius: 50%; 
  margin-right: 10px; 
} 

.message-input { 
  background-color: #000000;
  display: flex; 
  position:absolute;
  bottom: 0px;
  border-top: 0.5px solid #979595;
  align-items: center;
}


.has-search .form-control {
  padding-left: 2.375rem;
  background: #121212;
  color: #fff;
  border: 0.6px solid #e03f00;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2rem;
  text-align: center;
  pointer-events: none;
  color: #fff;
}

.custom-option{
  height: 40px;
}

.custom-option:hover{
  background-color: #cb2b10;
  background: #a14c3e;
}
.message-react{
  background: #28272764;
  border-radius: 30px;
  padding: 2px 6px;
  align-items: center;
  justify-content: center;
  text-align: center;
  // margin: 5px;
  display:none;
  border: none;
  position: relative;
  left: 10px;
}
.message-react-container{
  background: #121212;
  position: relative;
}
.message-reacted{
  background: #303030;
  border-radius: 30px;
  align-items: center;
  align-content: center;
  padding: 2px 6px;
  position: relative;
  bottom: 18px;
  right: -35px;
}

.receiver-message:hover .message-react{
  display: inline;
}

.no-data{
  border-radius: 10px !important;
  background-image: linear-gradient(#0b0b0b, #000000);
  border: 0.1px solid #d52b00;
}

.blank-chat{
    width: 200px !important;
    height: 250px !important;
}

.prof-avtar {
  width: 25px;
  height: 25px;
  padding: 3px;
  border: 1px solid #e92200;
  background: black;
  border-radius: 58px;
  margin-right: 10px;
}

.chat-image-container {
  width: 225px; /* Set your fixed width */
  height: 200px; /* Set your fixed height */
  overflow: hidden;
  position: relative;
}

.chat-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio while filling the container */
}


// Desktop Only ^^^^^^^^^^^^^^
@media screen and (min-width: 992px) {
  .hideindesk {
    display: none;
  }

  .leaderboard-switch{
    display: flex;
    flex-direction: column;
    gap: 18px;
    width: auto;
    justify-content: flex-start;
  }
}

// For Tablet ^^^^^^^^^^^^^^
@media (min-width:768px) and (max-width: 991px) {
  .hideindesk {
    display: none;
  }

  .chip-info-responsive-view{
    width: 40%;
  }
  
  .resetchip-responsive-view{
    display: grid !important;
    width: 55%;
  }

  .resetchip-responsive-scrollable-view{
    display: none !important;
  }

  .delay-box{
    max-width: 100%;
  }
}

// For Mobile ^^^^^^^^^^^^^^
@media (min-width:360px) and (max-width: 767px) {

  .coin-modal-margin {
    margin: 0 0;
  }

  /* // Dashboard section */
  // .club-card-container {
  //   margin: 17px;
  // }

  /* // Login Page */

  .signbtn {
    width: 200px;
  }

  .formcntr {
    margin-top: -25px;
  }

  /* // Create & Join Club Page */
  .cardwapper {
    padding-top: 110px;
  }

  .cardcontainer {
    background-color: #000000;
  }

  /* Profile Section*/
  .profile-card-container {
    background-color: black;
    margin: 0;
    margin-bottom: 50px;
  }

  .profile-divider {
    height: 0px;
  }

  /* Upload Pic */
  .upld-container {
    background-color: black;
  }

  // sec header
  .sec-header-first-items, .sec-header-sec-items{
    justify-content: space-between;
  }

  .welcome-title{
    display: none;
  }

  .hello-title {
    display: inline;
  }

  .create-club-button, .coin-wallet-valubet, .join-club-button{
    width: 31%;
  }

  .vb-banner{
    margin-top: -8px;
  }

  .sett-limit-btn {
    margin-top: 10px;
  }

  .delay-box{
    max-width: 100%;
  }
}


// ----------------------------------------------------------------------------------------------------------

// Mobile & Small Tab ^^^^^^^^^^^^^^
@media (min-width:611px) and (max-width: 767px) {
  .chip-info-responsive-view{
    width: 45%;
  }
  
  .resetchip-responsive-view{
    display: grid !important;
    width: 52%;
  }

  .resetchip-responsive-scrollable-view{
    display: none !important;
  }
  
}

// For Mobile Only ^^^^^^^^^^^^^^
@media (min-width:360px) and (max-width: 610px) {
  .list-nav-layout{
    margin-left: 68px;
    margin-top: 15px;
    width: 38%;
    display: flex;
    justify-content: space-around;
  }

  .desk-tokentransferbutton{
    display: none;
  }

  .mob-tokentransferbutton{
    display: unset;
  }

  .chip-info-responsive-view{
    width: 100%;
  }
  
  .resetchip-responsive-view{
    width: 108%;
    display: none !important;
  }

  .resetchip-responsive-scrollable-view{
    display: grid !important;
  }

  .message-input{
    width: 100% !important;
  }
}

// ----------------------------------------------------------------------------------------------------------
// For Small Mobile Only ^^^^^^^^^^^^^^
@media screen and (max-width: 359px) {

  .coin-modal-margin {
    margin: 0 0;
  }


  /* // Dashboard section */
  // .club-card-container {
  //   margin: 17px;
  // }

  /* // Login Page */

  .signbtn {
    width: 140px;
  }

  .formcntr {
    margin-top: -25px;
  }

  /* // Create & Join Club Page */
  .cardwapper {
    padding-top: 110px;
  }

  .cardcontainer {
    background-color: #000000;
  }

  /* Profile Section*/
  .profile-card-container {
    background-color: black;
    margin: 0;
    margin-bottom: 50px;
  }

  .profile-divider {
    height: 0px;
  }

  /* Upload Pic */
  .upld-container {
    background-color: black;
  }

  .list-nav-layout{
    margin-top: 60px;
    padding: 5px 15px;
    width: 100%;
    margin-left: -15px;
    background: #0b0b0b;
  }

  .club-title{
    display: none;
  }

  // sec header
  .sec-header-first-items, .sec-header-sec-items{
    justify-content: space-between;
  }

  .welcome-title{
    display: none;
  }

  .hello-title {
    display: inline;
    font-size: 12px;
  }

  .create-club-button, .coin-wallet-valubet, .join-club-button{
    width: 30%;
  }

  .desk-tokentransferbutton{
    display: none;
  }

  .mob-tokentransferbutton{
    display: unset;
  }

  .vb-banner{
    margin-top: -8px;
  }

  .chip-info-responsive-view{
    width: 100%;
  }
  
  .resetchip-responsive-view{
    width: 108%;
    display: none !important;
  }

  .resetchip-responsive-scrollable-view{
    display: grid !important;
  }

  .sett-limit-btn {
    margin-top: 10px;
  }

  .message-input{
    width: 100% !important;
  }

  .delay-box{
    max-width: 100%;
  }
}

// For Small to Normal Mobile Only ^^^^^^^^^^^^^^
@media screen and (max-width: 600px) {
  .date-fixed {
    padding-top: 20px;
    width: 100% !important;
  }
}
