@media (max-width: 767px) {
  .page-main-heading {
    margin-top: auto;
  }

  .pretty-card{
    width: auto;
  }

  .vb_detail li.right_carousel_img {
    text-align: center;
    padding-right: 0;
    margin-top: 0;
    height: 250px;
  }
}

@media (max-width: 992px) {

  .vb_detail .main_heading {
    margin-top: 60px;
  }

  .our_challenges .right_section_image img {
    width: 100%;
    height: auto;
  }

  .reward-section .left_section_image img {
    width: 100%;
    height: auto;
  }

  .sportsbook_label .sportsbook_label_line {
    gap: 0;
  }

  .vb_detail ol,
  ul {
    padding-left: 0;
  }

  .vb_detail .order_1 {
    order: 2;
  }

  .valubett_intro .left_section_image img {
    width: 100%;
  }

  .about_gaming .logo_image img {
    width: 100%;
    right: 0;
    left: 0;
  }

  .vb_detail h3.heading_v {
    font-size: 34px;
  }

  .vb_detail .carousel-indicators {
    bottom: -30px;
    right: 0;
    left: 0;
    text-align: right;
    justify-content: center;
    margin-right: 0;
    margin-left: 0;
  }

  .club_card {
    max-width: 100%;

  }

  .join_club .Vb_margin {
    margin-top: 260px;
  }

  .about_gaming .logo_image {
    margin-top: 30px;
  }

  .our_challenges h3.heading_v {
    font-size: 36px;
  }

  .reward-section h3.heading_v {
    font-size: 36px;
  }

  .about_gaming h3.heading_v {
    font-size: 46px;
  }

  .valubett_intro h3.heading_v {
    font-size: 36px;
  }

  section.our_challenges .order_1 {
    order: 2;
    margin-top: 45px;
  }

  .valubett_intro p.desc_v {
    padding: 0px 14px 0 0px;
  }

  #public-page {

    .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      padding-right: 32px;
      padding-left: 32px;
    }
  }

  .price-tabel-fonts {
    font-size: 10px;
  }
}

@media (max-width: 1150px) {
  .thank-you-card{
      padding: 0;
      clip-path: polygon(40% 0, -200% 100%, 100% 100%, 100% 0%, 100% 0)
  }
}